import React from "react";

const Maintenance = () => {
	return (
		<div
			style={{
				backgroundColor: "rgb(51, 50, 50)",
				color: "white",
				padding: "20px",
				height: "100vh",
				width: "100vw",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<h1>Site Under Maintenance</h1>
			<p>We apologize for the inconvenience. Please check back later.</p>
		</div>
	);
};

export default Maintenance;
