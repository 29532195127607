import React from "react";

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			message: "",
			isSubmitted: false,
			errors: {
				name: "",
				email: "",
				message: "",
			},
		};
	}

	handleInputChange = (event) => {
		const { name, value } = event.target;
		let errors = this.state.errors;

		switch (name) {
			case "name":
				errors.name =
					value.length < 5 ? "Name must be at least 5 characters long!" : "";
				break;
			case "email":
				const validEmailRegex = RegExp(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
				errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
				break;
			case "message":
				errors.message =
					value.length < 10
						? "Message must be at least 10 characters long!"
						: "";
				break;
			default:
				break;
		}

		this.setState({ errors, [name]: value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.validateForm(this.state.errors)) {
			fetch("https://formspree.io/f/xoqgvrrl", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(this.state),
			})
				.then((response) => response.json())
				.then((data) => console.log(data))
				.catch((error) => console.error("Error:", error));

			this.setState({
				name: "",
				email: "",
				message: "",
				isSubmitted: true,
			});
		}
	};

	validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
		return valid;
	};

	render() {
		const { errors } = this.state;
		return (
			<section className="contact-section">
				<h1>Get in Touch</h1>
				<form className="contact-form" onSubmit={this.handleSubmit}>
					<input
						type="text"
						name="name"
						placeholder="Your Name"
						required
						value={this.state.name}
						onChange={this.handleInputChange}
					/>
					{errors.name.length > 0 && (
						<span className="error">{errors.name}</span>
					)}
					<input
						type="email"
						name="email"
						placeholder="Your Email"
						required
						value={this.state.email}
						onChange={this.handleInputChange}
					/>
					{errors.email.length > 0 && (
						<span className="error">{errors.email}</span>
					)}
					<textarea
						name="message"
						placeholder="Your Message"
						required
						value={this.state.message}
						onChange={this.handleInputChange}
					/>
					{errors.message.length > 0 && (
						<span className="error">{errors.message}</span>
					)}
					<button type="submit">Send Message</button>
				</form>
				{this.state.isSubmitted && (
					<div className="notification">Message sent successfully!</div>
				)}
			</section>
		);
	}
}

export default Contact;
