import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Projects from "./components/Projects";
import { ImageList, ImageListItem } from "@mui/material";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Maintenance from "./components/Maintenance";

function App() {
	return (
		<div className="App">
			<Maintenance />
			{/* <div className="container">
				<Navbar />
				<div className="logo">
					<img src="FCT.png" alt="" />
				</div>
			</div>
			<div className="main">
				<div className="main__container">
					<Maintenance/>
					<Hero />
					<About />
					<Projects />
					<Contact />
				</div>
				<Footer />
			</div> */}
		</div>
	);
}

export default App;
